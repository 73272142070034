(function ($) {
    var $body = $('body');
    
    function mobileMenu() {
        var mm = false;
        $('#mobile-menu-toggle').click(function() {
            if(mm) {
                $('nav').slideUp();
                mm = false;
            }
            else {
                $('nav').slideDown();
                mm = true;
            }
        });
    }
    
    function menuHoverLine() {
        var currentMenuElement = $('.main-nav').find('.current');
        if(currentMenuElement.length > 0) {
            var currentMenuElementPosition = currentMenuElement.position();
            $('.hover-line').css('width', currentMenuElement.width()-30);
            $('.hover-line').css('left', currentMenuElementPosition.left+15);
            
            $('.main-nav li a').hover(function() {
                var itemWidth = $(this).width();
                var itemPosition = $(this).position();
                $('.hover-line').css('width', itemWidth);
                $('.hover-line').css('left', itemPosition.left+15);
            });

            $('.main-nav').mouseleave(function() {
                $('.hover-line').css('width', currentMenuElement.width()-30);
                $('.hover-line').css('left', currentMenuElementPosition.left+15);
            });
        }
        else {
            $('.hover-line').css('opacity', 0);
            
            $('.main-nav li a').hover(function() {
                $('.hover-line').css('opacity', 1);
                var itemWidth = $(this).width();
                var itemPosition = $(this).position();
                $('.hover-line').css('width', itemWidth);
                $('.hover-line').css('left', itemPosition.left+15);
            });

            $('.main-nav').mouseleave(function() {
                $('.hover-line').css('opacity', 0);
                $('.hover-line').css('width', currentMenuElement.width()-30);
                $('.hover-line').css('left', currentMenuElementPosition.left+15);
            });
        }
    }
    
    function langMenu() {
        $('#lang-toggle').click(function() {
            $('#lang-list').fadeIn();
        });
        
        $('#lang-list').mouseleave(function() {
            $(this).fadeOut();
        });
    }

    function homeProducts() {
        $('.home-baner__multiple-items').slick({
            speed: 500,
            arrows: true,
            infinite: true,
            autoplay: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
            prevArrow: false,
            nextArrow: '<button type="button" class="next-product"></button>',
            pauseOnDotsHover: false,
            responsive: [
                {
                  breakpoint: 1168,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 1000,
                  settings: {
                    slidesToShow: 1,
                  }
                },
            ]
        });
    }
    
    function testimonialsSlider() {
        $('.testimonials__multiple-items').slick({
            speed: 600,
            arrows: true,
            dots: true,
            infinite: true,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            prevArrow: false,
            nextArrow: '<button type="button" class="next-testimonial"></button>'
        });
    }
    
    function scrolBelow() {
        $('.scroll-below').click(function() {
            $("html, body").animate({ scrollTop: 800 }, 600);
            return false;
        });
    }
    
    function productSmallSlider() {
        $('.product-small-slider__multiple-items').slick({
            speed: 500,
            arrows: true,
            infinite: true,
            autoplay: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
            prevArrow: false,
            nextArrow: '<button type="button" class="next-product"></button>',
            pauseOnDotsHover: false,
            responsive: [
                {
                  breakpoint: 1168,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 1000,
                  settings: {
                    slidesToShow: 1,
                  }
                },
            ]
        });
    }
    
    function featuresSwitch() {
        $('#features-switch a').click(function() {
            if( !$(this).hasClass('active') ) {
                $('#features-switch a').removeClass('active');
                $(this).addClass('active');
                
                $('.features-list').slideUp();
                $('.features-list:nth-child(' + ($(this).index()+1) + ')').slideDown();
            }
        });
    }
    
    function otherProductsSlider() {
        $('.other-products__multiple-items').slick({
            speed: 500,
            arrows: true,
            infinite: true,
            autoplay: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
            prevArrow: false,
            nextArrow: '<button type="button" class="next-product"></button>',
            pauseOnDotsHover: false,
            responsive: [
                {
                  breakpoint: 1168,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 1000,
                  settings: {
                    slidesToShow: 1,
                  }
                },
            ]
        });
    }
    
    function showMoreFeatures() {
        $('#show-more-features').click(function() {
            if( $(this).attr('expand') === 'false' ) {
                $('.hidden-features').slideDown();
                $(this).attr('expand', 'true');
                $(this).html('Pokaż mniej');
            }
            else {
                $('.hidden-features').slideUp();
                $(this).attr('expand', 'false');
                $(this).html('Pokaż więcej');
            }
        });
    }
    
    function usageSidebar() {
//        $('.sidebar-menu').on('click', 'li', function(e) {
//            if( $(this).hasClass('have-submenu') ) {
//                e.preventDefault();
//                
//                if( $(this).attr('expand') == 'true' ) {
//                    $(this).find('.submenu').slideUp();
//                    $(this).attr('expand', 'false');
//                    
//                    $(this).children('.fas').removeClass('fa-angle-up');
//                    $(this).children('.fas').addClass('fa-angle-down');
//                }
//                else {
//                    $(this).find('.submenu').slideDown();
//                    $(this).attr('expand', 'true');
//                    
//                    $(this).children('.fas').removeClass('fa-angle-down');
//                    $(this).children('.fas').addClass('fa-angle-up');
//                }
//            }
//        });
        
//        $('.sidebar-menu').on('click', '.have-submenu', function(e) {
//            if( $(this).attr('expand') == 'true' ) {
//                $(this).find('.submenu').slideUp();
//                $(this).attr('expand', 'false');
//
//                $(this).children('.fas').removeClass('fa-angle-up');
//                $(this).children('.fas').addClass('fa-angle-down');
//            }
//            else {
//                $(this).find('.submenu').slideDown();
//                $(this).attr('expand', 'true');
//
//                $(this).children('.fas').removeClass('fa-angle-down');
//                $(this).children('.fas').addClass('fa-angle-up');
//            }
//        });
        
        $('.sidebar-menu').on('click', '.have-submenu a', function(e) {
            if( $(this).parent().attr('expand') == 'true' ) {
                $(this).next('.submenu').slideUp();
                $(this).parent().attr('expand', 'false');

                $(this).children('.fas').removeClass('fa-angle-up');
                $(this).children('.fas').addClass('fa-angle-down');
            }
            else {
                $(this).parent().find('.submenu').slideDown();
                $(this).parent().attr('expand', 'true');

                $(this).children('.fas').removeClass('fa-angle-down');
                $(this).children('.fas').addClass('fa-angle-up');
            }
        });
    }
    
    function fixedSmallProductSlider() {
        $(document).on('scroll', function() {
            if( $(this).scrollTop() >= $('.homepage #product-small-slider').position().top ){
                if (!popup) {
                    $('#product-small-slider').css('position', 'sticky');
                    $('#product-small-slider').css('opacity', 1);
                    $('#product-small-slider').css('z-index', '9999');
                }
            }
            else {
                $('#product-small-slider').css('opacity', 0);
                $('#product-small-slider').css('z-index', '-1');
            }
        });
    }
    
    var popup = false;
    function jakiProduktWybrac() {
        $('.toggle-co-wybrac').click(function() {
            $('.co-wybrac-popup').fadeIn();
            $('.co-wybrac-popup').css('display', 'flex');
            popup = true;
            
            var foo = $('.co-wybrac-popup').offset();
            $('.popup-bg').css('margin-top', '-' + foo.top + 'px' );
            $('.popup-bg').css('height', $('#wrapper').height() + 'px' );
            
            
            $('#product-small-slider').css('opacity', 0);
            $('#product-small-slider').css('z-index', '-1');
        });
        
        $('.close-popup').click(function() {
            $('.co-wybrac-popup').fadeOut();
            //reset choices
            $('.step').hide();
            $('.uprawa').show();
            $('.step-element').removeClass('checked');
            popup = false;
        });
        
//        var products = [
//            {
//                product: "Product brzoskwinia sady",
//                url: "http://youtube.com",
//                tags: "warzywa sady brzoskwinia"
//            },
//            {
//                product: "Do pomarańczy na polu",
//                url: "http://youtube.com",
//                tags: "polowe owoce-miekkie pomarancza"
//            },
//            {
//                product: "Lorem",
//                url: "http://youtube22323.com",
//                tags: "polowe owoce-miekkie pomarancza"
//            },
//            {
//                product: "Product do Kiwi",
//                url: "https://facebook.com",
//                tags: "warzywa sady kiwi"
//            }
//        ];
        
        /* Choises */
        var uprawa, rodzajUprawy, owoc;
        $('.uprawa .step-element').click(function() {
            uprawa = $(this).attr('step-data');
            
            $('.uprawa .step-element').removeClass('checked');
            $(this).addClass('checked');
            
            $('.rodzaj-uprawy').slideDown();
            filterProducts();
        });
        
        $('.rodzaj-uprawy .step-element').click(function() {
            rodzajUprawy = $(this).attr('step-data');
            
            $('.rodzaj-uprawy .step-element').removeClass('checked');
            $(this).addClass('checked');
            
            $('.owoc').slideDown();
            filterProducts();
        });
        
        $('.owoc .step-element').click(function() {
            owoc = $(this).attr('step-data');
            
            $('.owoc .step-element').removeClass('checked');
            $(this).addClass('checked');
            
            $('.result').slideDown();
            
            filterProducts();
        });
        
        
        
        function filterProducts() {
            var resultArray = [];
            
            var tags = uprawa +' '+ rodzajUprawy +' '+ owoc;
            
            for(var i = 0; i <= products.length-1; i++) {
                if( products[i].tags === tags ) {
                    resultArray.push(products[i]);
                }
            }
            
            if(resultArray.length > 1) {
                $('.step-title').html('Nasze propozycje');
            }
            else {
                $('.step-title').html('Nasza propozycja');
            }
            
            $('.results').html('');
            
            for(var i = 0; i <= resultArray.length-1; i++) {
                var html = '<div class="results__element"><h4 id="recommended-name">'+resultArray[i].product+'</h4><a href="'+resultArray[i].url+'" id="recommended-url" class="button button-primary">Zobacz stosować</a></div>';
                $('.results').append(html);
            }
        }
    }

    function formMore() {
        $(document).on('click', '.more-toggle', function() {
            var $more = $(this).parent().next('.more');
            
            if( $more.attr('expand') == 'false' ) {
                $more.slideDown();
                $more.attr('expand', 'true');
            }
            else {
                $more.slideUp();
                $more.attr('expand', 'false');
            }
        });
    }
    
    mobileMenu();
    menuHoverLine();
    langMenu();
    
    homeProducts();
    testimonialsSlider();

    scrolBelow();
    productSmallSlider();
    featuresSwitch();
    otherProductsSlider();
    
    showMoreFeatures();
    
    usageSidebar();
    fixedSmallProductSlider();
    
    jakiProduktWybrac();
    formMore();
    
})(jQuery);